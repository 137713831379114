<template>
  <v-dialog
    :transition="false"
    style="overflow: hidden"
    v-model="dialog"
    persistent
    :max-width="isMobile ? '300' : '400'"
  >
    <v-btn
      @click="closePopup"
      :class="[isMobile ? 'positionButtonMobile' : 'positionButtonDesk']"
      fab
      x-small
      absolute
      color="white"
    >
      <v-icon dark x-small> close </v-icon>
    </v-btn>
    <v-img
      :width="isMobile ? '300' : '400'"
      style="border-radius: 5px"
      :src="popup"
    ></v-img>
  </v-dialog>
</template>

<script>
export default {
  mounted() {
    this.getRecordCookie().then((resp) => {
      this.dialog = resp == "true" ? false : true;
      console.log("ckk", resp);
    });
    //this.sheet = this.$store.dispatch("getCookie", "acceptedCookie");
  },
  methods: {
    async getRecordCookie() {
      let resp = await this.$store.dispatch("getCookie", "antifraude");
      return resp;
    },
    closePopup() {
      // this.$store.dispatch("setCookie", {
      //   cname: "antifraude",
      //   cvalue: true,
      //   exdays: 0.02,
      // });
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: true,
      popup: require("@/assets/popup/popup-28-02-24.jpg"),
      // popup: require("@/assets/banners/antifraude/urgente-ok.jpg"),
      // popup: require("@/assets/banners/ir-popup/ir.jpg"),
    };
  },
  computed: {
    isMobile() {
      let v = this.$vuetify.breakpoint.name;
      if (v == "sm" || v == "xs") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.positionButtonMobile {
  left: calc(50% + 135px);
  top: calc(50% - 165px);
}
.positionButtonDesk {
  left: calc(50% + 185px);
  top: calc(50% - 215px);
}
.positionButtonDesk {
  left: calc(50% + 185px);
  top: calc(50% - 215px);
}
.bgAntifraude {
  z-index: 99999;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5) !important;
}
</style>