const state = {
  required: [v => !!v || 'Campo obrigatório'],
  requiredAutoComplete: [v => v.length > 0 || 'Campo obrigatório'],
  validZero: [v => actions.aceptZero(v) || 'Campo obrigatório'],
  requiredSelect: [v => v >= 0 || 'Campo obrigatório'],
  cepRules: [
    v => (v && v.match(/([0-9]{5})+(-?)+([\d]{3})/) != null) || 'CEP inválido'
  ],
  numberRules: [v => !!v || 'O número é obrigatório'],
  birthDateRules: [
    v => (v && v.match(/^\d{2}([./-])\d{2}\1\d{4}$/) != null) || 'Data inválida',
    v => actions.revalidDate(v) || 'Data inválida'
  ],
  genderRules: [v => v.length > 0 || 'Gênero inválido'],
  cpfCnpjRules: [
    v => (v && actions.validCpfCnpj(v)) || 'CPF ou CNPJ inválidos'
  ],
  emailRules: [
    v => (v && v.match(/^[A-Za-z0-9_\-.]+@[A-Za-z0-9_\-.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/) != null) || 'E-mail inválido'
  ],
  phoneRules: [v => (v && v.length === 10 || v && v.length === 11) || 'Telefone inválido'],
  rulesPass: {
    required: value => !!value || 'Não pode ser vazio.',
    min: v => (v && v.length >= 6) || 'Mínimo 6 caracters',
    matchPass: v =>
      (v && v === this.formData.password) || 'A senha não confere'
  },
  cellPhoneRules: [v => v.length === 11 || 'Celular inválido']
}
const actions = {
  aceptZero(val) {
    //console.log('zero')
    if (val == 0) { return true }
    if (val) { return true } else { return false }
  },
  revalidDate(state, date) {
    if (!date || date.length < 8) {
      return true
    }
    // var actualYear = new Date().getFullYear()
    var arr = date.split('/')

    var eighteenYears = new Date().getTime() - 568000800000
    var ageSelected = new Date(`${arr[1]}/${arr[0]}/${arr[2]}`).getTime()

    // var ageDonation = actualYear - parseInt(arr[2])
    if (
      parseInt(arr[0]) > 31 ||
      parseInt(arr[1]) > 12 ||
      ageSelected > eighteenYears
    ) {
      return false
    }
    return true
  },
  validCpfCnpj(value) {
    if (value.length <= 11) {
      var cpfSize = (value.replace(/[^a-zA-Z0-9 ]/g, ''))
      //value = $.trim(value)
      value = value.replace('.', '')
      value = value.replace('.', '')
      var cpf = value.replace('-', '')
      while (cpf.length < 11) {
        cpf = '0' + cpf
      }
      var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/
      var a = []
      var b = new Number()
      var c = 11
      var x, y
      for (let i = 0; i < 11; i++) {
        a[i] = cpf.charAt(i)
        if (i < 9) {
          b += (a[i] * --c)
        }
      }
      if ((x = b % 11) < 2) {
        a[9] = 0
      } else {
        a[9] = 11 - x
      }
      b = 0
      c = 11
      for (y = 0; y < 10; y++) {
        b += (a[y] * c--)
      }
      if ((x = b % 11) < 2) {
        a[10] = 0
      } else {
        a[10] = 11 - x
      }

      if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) {
        return false
      }

      if (cpfSize.length < 11) {
        return false
      }

      return true
    } else {
      var numeros, digitos, soma, i, resultado, pos, tamanho, digitosIguais
      if (value.length == 0) {
        return false
      }
      if (value.length < 14) {
        return false
      }

      value = value.replace(/\D+/g, '')
      digitosIguais = 1

      for (i = 0; i < value.length - 1; i++) {
        if (value.charAt(i) != value.charAt(i + 1)) {
          digitosIguais = 0
          break
        }
      }
      if (digitosIguais) {
        return false
      }

      tamanho = value.length - 2
      numeros = value.substring(0, tamanho)
      digitos = value.substring(tamanho)
      soma = 0
      pos = tamanho - 7
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) {
          pos = 9
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado != digitos.charAt(0)) {
        return false
      }
      tamanho = tamanho + 1
      numeros = value.substring(0, tamanho)
      soma = 0
      pos = tamanho - 7
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) {
          pos = 9
        }
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

      return (resultado == digitos.charAt(1))
    }
  }
}
export default {
  namespaced: true,
  root: true,
  state
}
