import { render, staticRenderFns } from "./Confirm-message.vue?vue&type=template&id=072c9432&"
import script from "./Confirm-message.vue?vue&type=script&lang=js&"
export * from "./Confirm-message.vue?vue&type=script&lang=js&"
import style0 from "./Confirm-message.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports