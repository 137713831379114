import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'siteArea',
    component: () => import('../views/SiteArea.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/portal-cliente',
        name: 'client',
        component: () => import('../views/CustomerPortal.vue')
      },
      {
        path: '/portal-corretor',
        name: 'realtor',
        component: () => import('../views/Realtor/RealtorPortal.vue')
      },
      {
        path: '/portal-corretor-campanhas',
        name: 'realtor-campaigns',
        component: () => import('../views/Realtor/RealtorCampaigns.vue')
      },
      {
        path: '/portal-corretor-fechamentos',
        name: 'realtor-campaigns',
        component: () => import('../views/Realtor/RealtorClosures.vue')
      },
      {
        path: '/portal-corretor-comunicados',
        name: 'realtor-announcements',
        component: () => import('../views/Realtor/RealtorAnnouncements.vue')
      },
      {
        path: '/tabelas-e-entidades',
        name: 'realtor-tables-entities',
        component: () => import('../views/Realtor/RealtorTablesEntities.vue')
      },
      {
        path: '/fale-conosco',
        name: 'contacts-view',
        component: () => import('../views/ContactView.vue')
      },
      {
        path: '/area-de-imprensa',
        name: 'news-area',
        component: () => import('../views/NewsArea.vue')
      },
      {
        path: '/governanca-corporativa',
        name: 'governance',
        component: () => import('../views/GovernanceView.vue')
      },
      {
        path: '/sustentabilidade-e-ESG',
        name: 'sustainability-esg',
        component: () => import('../views/SustainabilityEsg.vue')
      },
      {
        path: '/politica-de-privacidade',
        name: 'privacy-policy',
        component: () => import('../views/PrivacyPolicy.vue')
      },
    ]
  },
  {
    path: '/admArea',
    name: 'admArea',
    component: () => import('../views/AdmArea.vue'),
    children: [
      {
        adm: true,
        path: '/login',
        name: 'login',
        text: 'Login',
        icon: 'apartment',
        component: () => import('../components/admArea/LoginForm.vue')
      },
      {
        adm: true,
        path: '/usuarios',
        name: 'users',
        text: 'Usuários',
        icon: 'person',
        component: () => import('../components/admArea/users/ListUsers.vue')
      },
      {
        adm: true,
        path: '/campanhas',
        name: 'campaigns',
        text: 'Campanhas',
        icon: 'person',
        component: () => import('../components/admArea/campaigns/CampaignsList.vue')
      },
      {
        adm: true,
        path: '/comunicados',
        name: 'announcements',
        text: 'Comunicados',
        icon: 'person',
        component: () => import('../components/admArea/announcements/AnnouncementsList.vue')
      },
      {
        adm: true,
        path: '/fechamentos',
        name: 'closure',
        text: 'Fechamentos',
        icon: 'person',
        component: () => import('../components/admArea/closures/ClosuresList.vue')
      },
      {
        adm: true,
        path: '/tabelas-entidades',
        name: 'tableEntitie',
        text: 'Tabelas e entidades',
        icon: 'person',
        component: () => import('../components/admArea/tablesEntities/TablesEntities.vue')
      },
      {
        adm: true,
        path: '/assuntos-por-email',
        text: 'Assuntos por e-mail',
        name: 'subject-mail',
        icon: 'person',
        component: () => import('../components/admArea/emailSubject/EmailSubjectList.vue')
      },
      {
        adm: true,
        path: '/imprensa',
        text: 'CORPe na imprensa',
        name: 'imprensa',
        icon: 'person',
        component: () => import('../components/admArea/pressArea/PressAreaList.vue')
        //component: () => import('../components/admArea/newsArea/NewsArea.vue')
      },
      {
        adm: true,
        path: '/destaque-app',
        name: 'featured-image',
        text: 'Imagem de destaque',
        icon: 'wallpaper',
        component: () => import('../components/admArea/web-app-home/WebAppHome.vue')
      },
      {
        adm: true,
        path: '/esg-area',
        name: 'Ações ESG',
        text: 'ESG',
        icon: 'wallpaper',
        component: () => import('../components/admArea/esg/EsgArea.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
