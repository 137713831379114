const state = {
  confirmDialog: false,
  message: '',
  title: '',
  type: '',
  callback: null,
  btConfirm: { name: 'Ok', show: true },
  btCancel: { name: 'Cancelar', show: false }
}
const mutations = {
  showConfirmMessage: (state, opts) => {
    state.message = opts.message
    state.title = opts.title
    state.callback = opts.callback
    state.type = opts.type
    state.btConfirm = opts.btConfirm ? opts.btConfirm : state.btConfirm
    state.btCancel = opts.btCancel ? opts.btCancel : state.btCancel
    state.confirmDialog = true
  },
  closeConfirmMessage: (state) => {
    state.confirmDialog = false
  }
}
export default {
  namespaced: true,
  root: true,
  state,
  mutations
}
