<template>
  <v-app class="notranslate" translate="no">
    <BannerAntifraude v-if="false" />
    <ButtonAccessibility />
    <v-main>
      <router-view />
    </v-main>
    <loader :loaderOn="$store.state.loader.loaderOn" :linearLoader="$store.state.loader.linearLoader"
      :circleLoader="$store.state.loader.circleLoader"></loader>
    <snackbar :timeout="$store.state.snack.timeout" :snackMessage="$store.state.snack.snackMessage"
      :typeSnack="$store.state.snack.typeSnack"></snackbar>
    <confirm-message :message="$store.state.confirmMessage.message" :title="$store.state.confirmMessage.title"
      :type="$store.state.confirmMessage.type" :btConfirm="$store.state.confirmMessage.btConfirm"
      :btCancel="$store.state.confirmMessage.btCancel" :callback="$store.state.confirmMessage.callback"></confirm-message>
  </v-app>
</template>

<script>
import ButtonAccessibility from "./components/common/accessibility/ButtonAccessibility.vue";
import loader from "./components/common/Loader-page";
import snackbar from "./components/common/Alerts-messages";
import confirmMessage from "./components/common/Confirm-message";
import BannerAntifraude from "./components/common/antifraude/PopUp.vue";
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to && to.path == "/portal-cliente") {
          document.title = "CORPe Saúde | Portal do Cliente";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Acesse suas informações de planos, carteirinhas e boletos e +.');
        }
        if (to && to.path == "/tabelas-e-entidades") {
          document.title = "CORPe Saúde | Área do corretor";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Tabelas de vendas atualizadas, de todas as operadoras.');
        }
        if (to && to.path == "/portal-corretor-comunicados") {
          document.title = "CORPe Saúde | Área do corretor";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Acesse seu painel de vendas, comunicados e contratos, corretor.');
        }
        if (to && to.path == "/") {
          document.title = "CORPe Saúde | Sua gestora de saúde";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'CORPe Saúde | Planos de saúde para você e sua família | Mais de 50 operadoras e 20 estados | Seg a Sex das 08h as 18h.');
        }
        if (to && to.path == "/fale-conosco") {
          document.title = "CORPe Saúde | Fale conosco";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Contatos, cotações, perguntas frequentes e muito mais!');
        }
        if (to && to.path == "/area-de-imprensa") {
          document.title = "CORPe Saúde | CORPe na imprensa";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Confira todas as atualizações da CORPe na mídia.');
        }
        if (to && to.path == "/governanca-corporativa") {
          document.title = "CORPe Saúde | Governança corporativa";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Código de conduta, canal de denúncia e LGPD.');
        }
        if (to && to.path == "/sustentabilidade-e-ESG") {
          document.title = "CORPe Saúde | Sustentabilidade e ESG";
          let descEl = document.querySelector('head meta[name="description"]');
          descEl.setAttribute('content', 'Confira as ações que tornam a CORPe Sustentável.');
        }
      },
    },
    isMobile(v) {
      //console.log(v);
      if (v == "sm" || v == "xs") {
        this.$store.commit("updateGeneralScreenType", true);
      } else {
        this.$store.commit("updateGeneralScreenType", false);
      }
      console.log("tela watch", this.$store.state.mobileMode);
    },
  },
  components: {
    BannerAntifraude,
    ButtonAccessibility,
    loader,
    snackbar,
    confirmMessage,
  },
  mounted() {
    //console.log(`start`);
    //corporeadministradora
    if (window.location.href.indexOf("corporeadministradora") > -1) {
      let newUrl = window.location.href.replace(
        "corporeadministradora",
        "corpesaude"
      );
      window.location = newUrl;
    }
    // console.log('window')
    if (window.location.href.indexOf("www-homo") <= -1 && window.location.href.indexOf("localhost") <= -1) {
      if (window.location.protocol !== 'https:') {
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
      }
    }

    document.title = "CORPe Saúde | Sua gestora de saúde";
    let secreenSize = this.$vuetify.breakpoint.name;
    if (secreenSize == "sm" || secreenSize == "xs") {
      this.$store.commit("updateGeneralScreenType", true);
    }
    // setTimeout(() => {
    //   console.log("tela", this.$store.state.mobileMode);
    // }, 10);
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name;
    },
  },
};
</script>
<style>
/* @import './css/app.css'; */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.btMicro {
  min-width: auto !important;
  max-width: 36px !important;
}

.v-tooltip__content span {
  font-size: 12px !important;
}

.bt100 {
  width: 100%;
}

.forceLeftCbo .v-list-item__title {
  text-align: left !important;
}

.v-btn {
  text-transform: none !important;
}
</style>
