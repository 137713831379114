<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="accessibilityBox btAccessibility py-2"
      >
        <v-menu
          class="forceLeftCbo"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
          offset-y
          top
          left
          :menu-props="{ contentClass: 'forceLeftCbo' }"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              class="mx-2 btBorder btAccessibility"
              fab
              dark
            >
              <v-icon dark large> accessibility_new </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large color="grey darken-3">contrast</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title style="font-size: 16px" class="text-left"
                    >Acessibilidade</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="font-size: 14px"
                    class="text-left"
                    >configurações de acessibilidade</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <v-list>
              <v-list-item class="pl-6" style="font-size: 16px">
                Tamanho dos textos
                <v-spacer></v-spacer>
                <!-- v-model="toggle_multiple" -->
                <v-btn-toggle
                  dense
                  background-color="grey darken-3"
                  dark
                  multiple
                >
                  <v-btn @click="setSizeText('-')">
                    <v-icon>text_decrease</v-icon>
                  </v-btn>

                  <v-btn @click="setSizeText('+')">
                    <v-icon>text_increase</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-list-item></v-list
            >
            <v-divider></v-divider>
            <div
              style="font-size: 16px"
              class="text-left px-7 grey--text text--darken-3 mt-3 mb-2"
            >
              Esquema de cores
            </div>
            <v-radio-group v-model="radioGroup" class="mt-1">
              <v-list class="px-6">
                <v-radio
                  color="grey darken-3"
                  class="mb-3"
                  label="Normal"
                  value="normal"
                ></v-radio>
                <v-radio
                  color="grey darken-3"
                  class="mb-3"
                  label="Aumento de contraste"
                  value="hardContrast"
                ></v-radio>
                <v-radio
                  color="grey darken-3"
                  label="Escala de cinza"
                  value="greyScale"
                ></v-radio>
                <!-- <v-radio
                  color="grey darken-3"
                  class="mb-3"
                  label="Cor invertida"
                  value="invertColor"
                ></v-radio> -->
                <!-- <v-radio
                  color="grey darken-3"
                  class=""
                  label="Amarelo no preto"
                  value="yellowBlack"
                ></v-radio> -->
              </v-list>
            </v-radio-group>
          </v-card>
        </v-menu>
      </div>
    </template>
    <span>Acessibilidade</span>
  </v-tooltip>
</template>

<script>
export default {
  methods: {
    setSizeText(crease) {
      console.log("vazio");
      let actualSize = parseInt(
        document.querySelectorAll("html")[0].style.fontSize
      );
      if (crease == "+") {
        if (!actualSize) {
          document.querySelectorAll("html")[0].style.fontSize = "120%";
          document.getElementById("app").classList.add("size120");
          //document.getElementsByClassName('v-chip.v-size--small')
        }
        if (actualSize && actualSize == 120) {
          document.querySelectorAll("html")[0].style.fontSize = "150%";
          document.getElementById("app").classList.add("size150");
        }
        if (actualSize && actualSize == 150) {
          return;
        }
      } else {
        if (!actualSize) {
          return;
        }
        if (actualSize && actualSize == 120) {
          document.querySelectorAll("html")[0].style.fontSize = "";
          document.getElementById("app").classList.remove("size120");
        }
        if (actualSize && actualSize == 150) {
          document.querySelectorAll("html")[0].style.fontSize = "120%";
          document.getElementById("app").classList.remove("size150");
        }
      }
      // let tagsPText = document.querySelectorAll("p");
      // let len = tagsPText !== null ? tagsPText.length : 0;
      // for (let i = 0; i < len; i++) {
      //   if (crease == "+") {
      //     if (!tagsPText[i].style.fontSize) {
      //       tagsPText[i].style.fontSize = "20px";
      //     }
      //   } else {
      //     if (tagsPText[i].style.fontSize == "20px") {
      //       tagsPText[i].style.fontSize = "";
      //     }
      //   }
      // }
      //console.log(tagsPText[0].text, crease);
    },
    setImgsAccessibility(status) {
      // let imgsColor = document.getElementsByClassName("v-image");
      // let len = imgsColor !== null ? imgsColor.length : 0;

      document.getElementById("app").classList.remove("greyScale");
      document.getElementById("app").classList.remove("hardContrast");
      if (status) {
        document.getElementById("app").classList.add(status);
      }

      // for (let i = 0; i < len; i++) {
      //   imgsColor[i].classList.remove("greyScale");
      //   imgsColor[i].classList.remove("hardContrast");
      //   if (status) {
      //     imgsColor[i].classList.add(status);
      //   }
      // }
    },
    async setAccessibility() {
      let status = await this.$store.dispatch(
        "getCookie",
        "accessibilityStatus"
      );
      console.log("...", status);
      this.radioGroup = status;
      switch (status) {
        case "normal":
          document.getElementById("app").style.color = "#2c3e50";
          this.$vuetify.theme.themes.light = { ...this.originalcolors };
          // setTimeout(() => {
          //   this.setImgsAccessibility();
          // }, 10);
          break;

        case "greyScale":
          document.getElementById("app").style.color = "#111";
          this.$vuetify.theme.themes.light = { ...this.greyScale };
          // setTimeout(() => {
          //   this.setImgsAccessibility(status);
          // }, 10);
          break;

        case "hardContrast":
          document.getElementById("app").style.color = "#111";
          this.$vuetify.theme.themes.light = { ...this.hardContrast };
          // setTimeout(() => {
          //   this.setImgsAccessibility(status);
          // }, 10);
          break;

        default:
          document.getElementById("app").style.color = "#2c3e50";
          this.$vuetify.theme.themes.light = { ...this.originalcolors };
          // setTimeout(() => {
          //   this.setImgsAccessibility();
          // }, 10);
          break;
      }
      return status;
    },
  },
  mounted() {
    this.setAccessibility().then((status) => {
      this.$nextTick(() => {
        // console.log("test000000"); // runs only once
        this.setImgsAccessibility(status);
      });
    });
  },
  // ready: function () {
  //   this.setAccessibility().then((status) => {
  //     this.setImgsAccessibility(status);
  //   });
  // },
  watch: {
    radioGroup(v) {
      console.log(v);
      this.$store.dispatch("setCookie", {
        cname: "accessibilityStatus",
        cvalue: v,
        exdays: 1,
      });
      //this.$store.commit("updtaeAccessibilityStatus", this.radioGroup);
      this.setAccessibility().then((status) => {
        this.setImgsAccessibility(status);
      });
    },
  },
  data() {
    return {
      originalcolors: {
        btAccessibility: "#424242",
        vermelho_corpore: "#BD2730",
        cinza_corpore: "#676C72",
        preto: "#000000",
        primary: "#0075FF",
        azul: "#0075FF",
      },
      greyScale: {
        btAccessibility: "#000",
        vermelho_corpore: "#000",
        cinza_corpore: "#222",
        preto: "#000000",
        primary: "#000",
        azul: "#0075FF",
      },
      hardContrast: {
        btAccessibility: "#424242",
        vermelho_corpore: "#670001",
        cinza_corpore: "#222",
        preto: "#000000",
        primary: "#00198e",
        azul: "#00198e",
      },
      radioGroup: "",
      menu: false,
    };
  },
};
</script>

<style>
.accessibilityBox {
  position: fixed;
  top: 300px;
  right: 0;
  z-index: 1;
  border-radius: 5px 0 0 5px;
}
.btBorder {
  border: 1px solid #fff !important;
}
#app.hardContrast .v-image {
  filter: contrast(200%) brightness(60%);
}
#app.greyScale .v-image {
  filter: grayscale(100%);
}
#app.size120 .v-chip.v-size--small {
  font-size: 14px;
}
#app.size150 .v-chip.v-size--small {
  font-size: 16px;
}
#app.size120 .v-breadcrumbs__item {
  font-size: 18px;
}
#app.size150 .v-breadcrumbs__item {
  font-size: 20px;
}
#app.size120 .footer-text,
#app.size120 .footer-text-right {
  font-size: 20px;
}
#app.size150 .footer-text,
#app.size150 .footer-text-right {
  font-size: 22px;
}
#app.size120 .ansArea,
#app.size120 .ansTitle {
  font-size: 11px;
}
#app.size150 .ansArea,
#app.size150 .ansTitle {
  font-size: 13px;
}
</style>